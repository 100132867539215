import MarkdownComponent from '@jwp/ott-ui-react/src/components/MarkdownComponent/MarkdownComponent';

import styles from './VideoAboutSection.module.scss';

const VideoAboutSection = ({ description, metadata }: { description: string; metadata: React.ReactElement }) => {
  return (
    <div className={styles.aboutContainer}>
      <div className={styles.aboutDescription}>
        <MarkdownComponent className={styles.markdown} markdownString={description} headerOffset={1} />
      </div>
      <div className={styles.aboutMetadata}>{metadata}</div>
    </div>
  );
};

export default VideoAboutSection;
