import React, { type PropsWithChildren } from 'react';
import Fade from '@jwp/ott-ui-react/src/components/Animation/Fade/Fade';

export type Props = {
  title: string;
  className?: string;
};

const Tab = ({ children, className }: PropsWithChildren<Props>) => (
  <div className={className} role="tabpanel">
    <Fade open duration={500}>
      {children}
    </Fade>
  </div>
);

export default Tab;
