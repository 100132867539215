import React from 'react';
import classNames from 'classnames';

import type { Props as TabProps } from '../Tab/Tab';

import styles from './Tabs.module.scss';

type MakeArray<T> = T | T[];
type Children = MakeArray<React.ReactElement<TabProps> | null>;

type Props = {
  children: Children;
  onTabSelect: (index: number) => void;
  selectedTab: number;
  className?: string;
};

type TabButtonProps = {
  index: number;
  title: string;
  onTabSelect: () => void;
  selectedTab: boolean;
};

const TabButton = ({ selectedTab, onTabSelect, index, title }: TabButtonProps) => {
  return (
    <li key={index} role="tab" aria-controls="tab-content" aria-selected={selectedTab ? 'true' : 'false'}>
      <button onClick={onTabSelect} className={classNames(styles.tabButton, { [styles.active]: selectedTab })} data-title={title}>
        {title}
      </button>
    </li>
  );
};

const Tabs: React.FC<Props> = ({ children, selectedTab, onTabSelect, className }) => {
  const tabs = React.Children.toArray(children) as React.ReactElement<TabProps>[];

  return (
    <>
      <ul className={classNames(styles.tabHeader, className)} role="tablist">
        {tabs.map((item, index) => (
          <TabButton selectedTab={selectedTab === index} key={item.props.title} index={index} title={item.props.title} onTabSelect={() => onTabSelect(index)} />
        ))}
      </ul>
      <div id="tab-content" className={styles.tabsContent}>
        {tabs[selectedTab]}
      </div>
    </>
  );
};

export default Tabs;
