import React from 'react';

import MarkdownComponent from '../../components/MarkdownComponent/MarkdownComponent';

import markdown from './licenses.md?raw';
import styles from './Licenses.module.scss';

// VD Private
const Licenses = () => {
  return <MarkdownComponent className={styles.licenses} markdownString={markdown} />;
};

export default Licenses;
