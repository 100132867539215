import React from 'react';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import ErrorPage from '@jwp/ott-ui-react/src/components/ErrorPage/ErrorPage';
import RootErrorPage from '@jwp/ott-ui-react/src/components/RootErrorPage/RootErrorPage';
import About from '@jwp/ott-ui-react/src/pages/About/About';
import Licenses from '@jwp/ott-ui-react/src/pages/Licenses/Licenses';
import Home from '@jwp/ott-ui-react/src/pages/Home/Home';
import Search from '@jwp/ott-ui-react/src/pages/Search/Search';
import User from '@jwp/ott-ui-react/src/pages/User/User';
import LegacySeries from '@jwp/ott-ui-react/src/pages/LegacySeries/LegacySeries';
import MediaScreenRouter from '@jwp/ott-ui-react/src/pages/ScreenRouting/MediaScreenRouter';
import PlaylistScreenRouter from '@jwp/ott-ui-react/src/pages/ScreenRouting/PlaylistScreenRouter';
import Layout from '@jwp/ott-ui-react/src/containers/Layout/Layout';
import {
  PATH_ABOUT,
  PATH_CONTENT_LIST,
  PATH_LEGACY_SERIES,
  PATH_LICENSES,
  PATH_MEDIA,
  PATH_PLAYLIST,
  PATH_SEARCH,
  PATH_TV,
  PATH_USER,
} from '@jwp/ott-common/src/paths';
import { APP_CONFIG_ITEM_TYPE } from '@jwp/ott-common/src/constants';
import { createURL } from '@jwp/ott-common/src/utils/urlFormatting';

import RoutesContainer from '#src/containers/RoutesContainer/RoutesContainer';

export default function AppRoutes() {
  const { t } = useTranslation('error');

  return (
    <Routes>
      <Route element={<RoutesContainer />}>
        <Route element={<Layout />} errorElement={<RootErrorPage />}>
          <Route index element={<Home />} />
          <Route path={PATH_PLAYLIST} element={<PlaylistScreenRouter type={APP_CONFIG_ITEM_TYPE.playlist} />} />
          <Route path={PATH_CONTENT_LIST} element={<PlaylistScreenRouter type={APP_CONFIG_ITEM_TYPE.content_list} />} />
          <Route path={PATH_MEDIA} element={<MediaScreenRouter />} />
          <Route path={PATH_LEGACY_SERIES} element={<LegacySeries />} />
          <Route path={PATH_SEARCH} element={<Search />} />
          <Route path={PATH_USER} element={<User />} />
          <Route path={PATH_ABOUT} element={<About />} />
          <Route
            path="/*"
            element={<ErrorPage title={t('notfound_error_heading', 'Not found')} message={t('notfound_error_description', "This page doesn't exist.")} />}
          />
          {/* VD Private code */}
          <Route path={PATH_LICENSES} element={<Licenses />} />
          <Route path={PATH_TV} element={<Navigate to={createURL(`/${window.location.search}`, { u: 'login-device' })} />} />
        </Route>
      </Route>
    </Routes>
  );
}
