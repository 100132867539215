import React, { useState } from 'react';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { getModule } from '@jwp/ott-common/src/modules/container';
import { useConfigStore } from '@jwp/ott-common/src/stores/ConfigStore';
import AccountController from '@jwp/ott-common/src/controllers/AccountController';
import useForm from '@jwp/ott-hooks-react/src/useForm';
import { createURLFromLocation } from '@jwp/ott-ui-react/src/utils/location';
import useDeviceFlowVerification from '@jwp/ott-hooks-react/src/useDeviceFlowVerification';
import useSocialLoginUrls from '@jwp/ott-hooks-react/src/useSocialLoginUrls';
import type { LoginFormData } from '@jwp/ott-common/types/account';
import { useAccountStore } from '@jwp/ott-common/src/stores/AccountStore';
import { createURL } from '@jwp/ott-common/src/utils/urlFormatting';

import LoginForm from '../../../components/LoginForm/LoginForm';
import { useAriaAnnouncer } from '../../AnnouncementProvider/AnnoucementProvider';
import DeviceFlowVerification, { type DeviceFlowLoginData } from '../../../components/DeviceFlowVerification/DeviceFlowVerification';
import useQueryParam from '../../../hooks/useQueryParam';

// VD Private
const LoginDevice = () => {
  const accountController = getModule(AccountController);
  const { siteName } = useConfigStore((s) => s.config);
  const user = useAccountStore((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('account');
  const announce = useAriaAnnouncer();

  const authorizeCode = useDeviceFlowVerification();
  const code = useQueryParam('code') ?? undefined;
  const deviceName = useQueryParam('deviceName') ?? undefined;
  const [success, setSuccess] = useState(false);

  const socialLoginURLs = useSocialLoginUrls(window.location.href.split('?')[0]);

  const { values, errors, submitting, validationSchemaError, handleSubmit, handleChange } = useForm<LoginFormData>({
    initialValues: { email: '', password: '' },
    validationSchema: object().shape({
      email: string()
        .email(t('login.field_is_not_valid_email'))
        .required(t('login.field_required', { field: t('login.email') })),
      password: string().required(t('login.field_required', { field: t('login.password') })),
    }),
    onSubmit: ({ email, password }) => accountController.login(email, password, window.location.href),
    onSubmitSuccess: () => {
      announce(t('login.sign_in_success'), 'success');
    },
    onSubmitError: ({ resetValue }) => resetValue('password'),
  });

  const deviceFlowForm = useForm<DeviceFlowLoginData>({
    initialValues: { code: '' },
    onSubmit: async (values) => {
      if (!code && values.code) {
        return navigate(createURLFromLocation(location, { code: values.code }));
      }
      if (code) {
        await authorizeCode.mutateAsync(code);

        announce(t('login.sign_in_success'), 'success');
        setSuccess(true);
      }
    },
  });

  const handleClose = () => navigate(createURL(location.pathname, {}));

  if (!user) {
    return (
      <>
        <div role="alert" style={{ borderRadius: '8px', padding: '16px', margin: '16px 0', lineHeight: 1.5, backgroundColor: 'rgba(234, 179, 8, .16)' }}>
          {t('login_device.interruption_alert')}
        </div>
        <LoginForm
          values={values}
          validationError={validationSchemaError}
          errors={errors}
          submitting={submitting}
          siteName={siteName}
          socialLoginURLs={socialLoginURLs}
          onSubmit={handleSubmit}
          onChange={handleChange}
        />
      </>
    );
  }

  return (
    <DeviceFlowVerification
      hasAccess={success}
      code={code}
      userName={user.fullName || user.email}
      deviceName={deviceName}
      authorizationError={authorizeCode.error}
      onClose={handleClose}
      values={deviceFlowForm.values}
      validationError={deviceFlowForm.validationSchemaError}
      errors={deviceFlowForm.errors}
      submitting={deviceFlowForm.submitting}
      onSubmit={deviceFlowForm.handleSubmit}
      onChange={deviceFlowForm.handleChange}
    />
  );
};

export default LoginDevice;
