import { inject, injectable } from 'inversify';

import type {
  AddAdyenPaymentDetails,
  CreateOrder,
  CreateOrderPayload,
  DeletePaymentMethod,
  FinalizeAdyenPaymentDetails,
  GetAdyenPaymentSession,
  GetEntitlements,
  GetFinalizeAdyenPayment,
  GetInitialAdyenPayment,
  GetOffer,
  GetOffers,
  GetOrder,
  GetPaymentMethods,
  GetSubscriptionSwitch,
  GetSubscriptionSwitches,
  IAPStore,
  Offer,
  PaymentWithoutDetails,
  PaymentWithPayPal,
  SwitchSubscription,
  UpdateOrder,
  UpdateOrderResponse,
  UpdatePaymentWithPayPal,
} from '../../../../types/checkout';
import CheckoutService from '../CheckoutService';
import { GET_CUSTOMER_IP } from '../../../modules/types';
import type { GetCustomerIP } from '../../../../types/get-customer-ip';
import type { ServiceResponse } from '../../../../types/service';
import { logDebug } from '../../../logger';

import CleengService from './CleengService';

type CleengV2Offer = {
  id: string;
  title: string;
  description: string;
  price: {
    amount: number;
    currency: string;
    taxIncluded: boolean;
  };
  active: boolean;
  type: string;
  geoRestriction?: {
    type: string | null;
    countries: string[];
  };
  billingCycle?: {
    periodUnit: string;
    amount: number;
  };
  entitlement?: {
    expirationPolicy: string;
    expiresAt?: number;
    duration?: {
      amount: number;
      periodUnit: string;
    };
  };
  event?: {
    startsAt: number;
    endsAt: number;
    timezone: string;
  };
  freeTrial: {
    type: string;
    duration: number;
  };
  createdAt: number;
  updatedAt: number;
  tags: string[];
  externalProperties: Record<string, string>;
  appStoreProductIds: { apple: string; android: string };
  imageUrl: string;
  hasLandingPage: false;
  localizations: {
    id: string;
    title: string;
    description?: string;
    countryCode: string;
    price: {
      amount: number;
      currency: string;
      taxIncluded: boolean;
    };
    freeTrial?: {
      type: string;
      duration: number;
    };
    geoRestriction?: {
      type: string;
      countries: string[];
    };
  }[];
  longId: string;
  sessionsLimit: number | null;
  giftable: boolean;
};

@injectable()
export default class CleengCheckoutService extends CheckoutService {
  protected readonly cleengService: CleengService;
  protected readonly getCustomerIP: GetCustomerIP;

  constructor(@inject(CleengService) cleengService: CleengService, @inject(GET_CUSTOMER_IP) getCustomerIP: GetCustomerIP) {
    super();
    this.cleengService = cleengService;
    this.getCustomerIP = getCustomerIP;
  }

  getOffers: GetOffers = async ({ offerIds, customerCountry }) => {
    return await Promise.all(
      offerIds.map(async (offerId) => {
        const response = await this.getOffer({ offerId: String(offerId), customerCountry });

        if (response.errors.length > 0) {
          throw new Error(response.errors[0]);
        }

        return response.responseData;
      }),
    );
  };

  getOffer: GetOffer = async ({ offerId, customerCountry }) => {
    const cleanOfferId = offerId.split('_')[0];
    const response = await this.cleengService.get(`/v2/offers/${cleanOfferId}`);
    const { responseData: offerV2, errors } = response as {
      responseData: CleengV2Offer;
      errors: string[];
    };

    const longIdCountryCode = offerV2.longId.split('_')[1];

    const localizedOffer = customerCountry ? offerV2.localizations.find(({ countryCode }) => countryCode === customerCountry) : undefined;
    const offerCountry = localizedOffer?.countryCode || longIdCountryCode;
    const { title, price } = localizedOffer || offerV2;
    const freeTrial = localizedOffer?.freeTrial || offerV2.freeTrial;
    const geoRestriction = localizedOffer?.geoRestriction || offerV2.geoRestriction;

    const offerV1: Offer = {
      id: null,
      offerId: `${cleanOfferId}_${offerCountry}`, // Should include the countryCode
      offerPrice: price.amount,
      offerCurrency: price.currency,
      offerCurrencySymbol: '', // Unused
      offerCountry,
      appleStoreProductId: offerV2.appStoreProductIds.apple,
      googlePlayProductId: offerV2.appStoreProductIds.android,
      customerPriceInclTax: price.amount,
      customerPriceExclTax: 0, // Unused
      customerCurrency: price.currency,
      customerCurrencySymbol: '', // Unused
      customerCountry: customerCountry || longIdCountryCode,
      discountedCustomerPriceInclTax: null,
      discountedCustomerPriceExclTax: null,
      discountPeriods: null,
      offerUrl: null,
      offerTitle: title,
      offerDescription: null,
      active: offerV2.active,
      createdAt: offerV2.createdAt,
      updatedAt: offerV2.updatedAt,
      applicableTaxRate: 0, // Unused
      geoRestrictionEnabled: !!offerV2.geoRestriction,
      geoRestrictionType: offerV2.geoRestriction?.type || null,
      geoRestrictionCountries: geoRestriction?.countries || [],
      socialCommissionRate: 0, // Unused
      averageRating: 0, // Unused
      contentType: null, // Unused
      period: (offerV2.billingCycle?.periodUnit as 'day' | 'week' | 'month' | 'year') || 'month',
      freePeriods: freeTrial?.type === 'period' ? freeTrial.duration : 0,
      freeDays: freeTrial?.type === 'day' ? freeTrial.duration : 0,
      expiresAt: offerV2.entitlement?.expiresAt?.toString() || null,
      accessToTags: offerV2.tags,
      videoId: null, // Unused
      contentExternalId: null, // Unused
      contentExternalData: null, // Unused
      contentAgeRestriction: null, // Unused
    };

    return { responseData: offerV1, errors };
  };

  createOrder: CreateOrder = async (payload) => {
    const locales = await this.cleengService.getLocales();

    if (locales.errors.length > 0) throw new Error(locales.errors[0]);

    const customerIP = locales.responseData.ipAddress;

    const createOrderPayload: CreateOrderPayload = {
      offerId: payload.offer.offerId,
      customerId: payload.customerId,
      country: payload.country,
      currency: locales?.responseData?.currency || 'EUR',
      customerIP,
      paymentMethodId: payload.paymentMethodId,
    };

    return this.cleengService.post('/orders', JSON.stringify(createOrderPayload), { authenticate: true });
  };

  getOrder: GetOrder = async ({ orderId }) => {
    return this.cleengService.get(`/orders/${orderId}`, { authenticate: true });
  };

  updateOrder: UpdateOrder = async ({ order, ...payload }) => {
    const response = await this.cleengService.patch<ServiceResponse<UpdateOrderResponse>>(`/orders/${order.id}`, JSON.stringify(payload), {
      authenticate: true,
    });

    if (response.errors.length) {
      if (response.errors[0].includes(`Order with ${order.id} not found`)) {
        throw new Error('Order not found');
      }

      if (response.errors[0].includes(`Coupon ${payload.couponCode} not found`)) {
        throw new Error('Invalid coupon code');
      }

      if (response.errors[0].includes(`Coupon ${payload.couponCode} cannot be applied on this offer`)) {
        throw new Error('Invalid coupon code for this offer');
      }
    }

    return response;
  };

  getPaymentMethods: GetPaymentMethods = async () => {
    return this.cleengService.get('/payment-methods', { authenticate: true });
  };

  paymentWithoutDetails: PaymentWithoutDetails = async (payload) => {
    return this.cleengService.post('/payments', JSON.stringify(payload), { authenticate: true });
  };

  paymentWithPayPal: PaymentWithPayPal = async (payload) => {
    const { order, successUrl, cancelUrl, errorUrl, captchaValue } = payload;

    const paypalPayload = {
      orderId: order.id,
      successUrl,
      cancelUrl,
      errorUrl,
      captchaValue,
    };

    return this.cleengService.post('/connectors/paypal/v1/tokens', JSON.stringify(paypalPayload), { authenticate: true });
  };

  getSubscriptionSwitches: GetSubscriptionSwitches = async (payload) => {
    return this.cleengService.get(`/customers/${payload.customerId}/subscription_switches/${payload.offerId}/availability`, { authenticate: true });
  };

  getSubscriptionSwitch: GetSubscriptionSwitch = async (payload) => {
    return this.cleengService.get(`/subscription_switches/${payload.switchId}`, { authenticate: true });
  };

  switchSubscription: SwitchSubscription = async (payload) => {
    return this.cleengService.post(
      `/customers/${payload.customerId}/subscription_switches/${payload.offerId}`,
      JSON.stringify({ toOfferId: payload.toOfferId, switchDirection: payload.switchDirection }),
      { authenticate: true },
    );
  };

  getEntitlements: GetEntitlements = async (payload) => {
    return this.cleengService.get(`/entitlements/${payload.offerId}`, { authenticate: true });
  };

  createAdyenPaymentSession: GetAdyenPaymentSession = async (payload) => {
    return await this.cleengService.post('/connectors/adyen/sessions', JSON.stringify(payload), { authenticate: true });
  };

  initialAdyenPayment: GetInitialAdyenPayment = async (payload) =>
    this.cleengService.post(
      '/connectors/adyen/initial-payment',
      JSON.stringify({ ...payload, attemptAuthentication: this.cleengService.sandbox ? 'always' : undefined }),
      { authenticate: true },
    );

  finalizeAdyenPayment: GetFinalizeAdyenPayment = async (payload) =>
    this.cleengService.post('/connectors/adyen/initial-payment/finalize', JSON.stringify(payload), { authenticate: true });

  updatePaymentMethodWithPayPal: UpdatePaymentWithPayPal = async (payload) => {
    return this.cleengService.post('/connectors/paypal/v1/payment_details/tokens', JSON.stringify(payload), { authenticate: true });
  };

  deletePaymentMethod: DeletePaymentMethod = async (payload) => {
    return this.cleengService.remove(`/payment_details/${payload.paymentDetailsId}`, { authenticate: true });
  };

  addAdyenPaymentDetails: AddAdyenPaymentDetails = async (payload) =>
    this.cleengService.post(
      '/connectors/adyen/payment-details',
      JSON.stringify({
        ...payload,
        attemptAuthentication: this.cleengService.sandbox ? 'always' : undefined,
      }),
      { authenticate: true },
    );

  finalizeAdyenPaymentDetails: FinalizeAdyenPaymentDetails = async (payload) =>
    this.cleengService.post('/connectors/adyen/payment-details/finalize', JSON.stringify(payload), { authenticate: true });

  directPostCardPayment = async () => false;

  /**
   * VD Private code below
   */
  forwardStoreReceipt = async (store: IAPStore, offer: Offer, receipt: string, transactionId?: string, customerId?: string, customerEmail?: string) => {
    logDebug('CleengCheckoutService', 'Lets deliver', { store, offer, transactionId, customerId });
    try {
      if (store === 'google') {
        const payload = {
          customerEmail,
          offerId: offer.offerId,
          receipt: JSON.parse(receipt),
          ipAddress: await this.getCustomerIP(),
        };

        await this.cleengService.post('/android/payment', JSON.stringify(payload), { authenticate: true });
      } else if (store === 'apple') {
        const payload = {
          ipAddress: await this.getCustomerIP(),
          originalTransactionId: transactionId,
          cleengCustomerId: customerId,
        };
        await this.cleengService.performInAppAPIRequest('/storekit2/purchase-validation', 'POST', JSON.stringify(payload), { authenticate: true });
      }

      return true;
    } catch (error) {
      logDebug('CleengCheckoutService', 'Error delivering', { store, offer, receipt, error });
    }

    return false;
  };
}
