import React from 'react';

const VideoDefinitionList = ({
  className,
  listProperties,
  splitByComma = true,
}: {
  className?: string;
  listProperties: { label: string; value: string | React.ReactElement }[];
  splitByComma?: boolean;
}) => {
  const filledProperties = listProperties.filter(({ value }) => value);

  if (filledProperties.length === 0) return null;

  return (
    <dl className={className}>
      {filledProperties.map(({ label, value }) => (
        <div key={label}>
          <dt>{label}:</dt>
          {splitByComma && typeof value === 'string' ? value.split(',').map((val) => <dd key={val}>{val}</dd>) : <dd>{value}</dd>}
        </div>
      ))}
    </dl>
  );
};

export default VideoDefinitionList;
